@import "../../assets/scss/mixin";

.panel-sidebar {
  z-index: 9;
  width: 239px;
  height: 100vh;
  background: radial-gradient(158.37% 100% at 50% 0%, #1A1A2E 0%, #0D0D17 100%);
  padding: 14px 16px 200px 16px;
  position: fixed;
  left: 0;
  top: 0;
  @include transition;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: $breakpoint_tablet) {
    left: -100%;

    &.show {
      left: 0;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: .3rem;
  }

  figure {
    margin: 0 0 57px;
    text-align: center;

    a {
      display: inline-block;

      img {
        max-width: 191px;
        width: 100%;
      }
    }
  }

  ul {
    &.main-menu {
      height: calc(100vh - 310px);
      overflow: auto;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    &.bottom-area {
      position: absolute;
    bottom: 32px;
    width: 100%;
    left: 0;
    right: 0;
    padding: 0 16px;
    }

    li {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0
      }

      a {
        display: flex;
        font-size: 16px;
        align-items: center;
        color: #99C8ED;
        padding: 8px;
        @include transition;
        text-decoration: none;
        &.active-link {
          background: #01599E;
          border-radius: 10px;
          text-decoration: none;
        }

        &:hover {
          background: #01599E;
          border-radius: 10px;
          text-decoration: none;
        }

        img {
          width: 24px;
          object-fit: contain;
          margin: 0 8px 0 0;
        }
      }
    }
  }
}