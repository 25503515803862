$dark_color: #07070C;
$white_color: #FFFFFF;
$theme_color: #1A1A2E;
$w_100: 100%;
$position_rel: relative;
$position_abs: absolute;
$hidden: hidden;
$transparent: transparent;
$flex: flex;
$margin_auto: 0 auto;
$breakpoint_mobile_landscape: 767px;
$breakpoint_mobile_portrait: 600px;
$breakpoint_small_portrait: 320px;
$breakpoint_tablet: 999px;
$breakpoint_mini_laptop: 1200px;
$breakpoint_minix_laptop: 1300px;
$breakpoint_laptop: 1600px;
$d_none: none;
$d_block: block;
