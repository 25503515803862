@import "../../assets/scss/mixin";

.head-title {
  margin-bottom: 24px;

  small {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: block;
    color: #767682;
    margin: 0 0 24px;
  }

  h2 {
    margin: 0;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
  }
}

.table-responsive {
  &.alternative-colors {
    table {
      tr {
        th {
          background: #1A1A2E;
          color: #F8F8F8;
          font-weight: 400;
        }
      }

      tbody {
        tr {
          &:nth-child(odd) {
            background: #F1F1F1;
          }
        }
      }
    }

    div {
      &[role="table"] {
        [role="rowgroup"] {
          &:first-child {
            [role=row] {
              &>div {
                background: #1A1A2E;
              }

              .table-content {
                color: #F8F8F8;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  &>div {
    &:last-child {
      nav {
        background-color: transparent;
        justify-content: flex-start;

        &>div {
          &:last-child {
            margin-left: auto
          }
        }
      }
    }
  }

  div {
    &[role="table"] {
      background-color: transparent;

      [role="rowgroup"] {
        &:first-child {
          [role="row"] {
            &>div {
              &:first-child {
                border-radius: 10px 0 0 0;
              }

              &:last-child {
                border-radius: 0 10px 0 0;
              }
            }
          }

          .table-content {
            font-weight: 700;
          }
        }

        [role="row"] {
          background-color: transparent;
          border: none;
          height: 51px;

          figure {
            margin: 0;
            display: flex;
            align-items: center;

            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            img {
              display: flex;
              width: 35px;
              flex: 0 0 35px;
              margin-right: 10px;
              height: 35px;
              border-radius: 100%;
              object-fit: cover;
            }
          }

          &>div {
            border: 1px solid #E3E3E3;
            text-align: left;
            padding: 8px 24px 8px 24px;
            border-right: none;
            border-bottom: none;

            &:last-child {
              border-right: 1px solid #E3E3E3
            }
          }

          .table-content {
            color: #07070C;
            font-family: 'Nunito', sans-serif;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.add-user-wrapper {
  display: flex;

  .add-form {
    width: calc(100% - 212px);
    padding-right: 48px;
  }

  .file-uploader {
    max-width: 212px;
  }
}

.add-form {
  ul {
    margin: 0 -8px;

    &.position-color {

      //flex-wrap: nowrap;
      li {
        &:first-child {
          width: calc(100% - 148px);
        }

        width: auto;
      }
    }

    li {
      width: 100%;
      padding: 0 8px;
      margin-bottom: 10px;

      h6 {
        color: #121212;
        margin: 10px 0 10px;
      }
    }
  }
}

.file-uploader {
  width: 100%;

  figure {
    margin: 0 0 18px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #E3E3E3;

    img {
      width: 100%;
      max-width: 212px;
      height: 212px;
      object-fit: cover;
    }
  }

  button {
    position: relative;

    input {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }
  }
}