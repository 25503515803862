.replacement {
  border: 1px solid #e3e3e3;
  background: #f1f1f1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  margin-top: 10px;
  img {
    width: 35px;
    height: 35px;
    border-radius: 100px;
    object-fit: cover;
    margin-right: 10px;
  }
  p {
    font-weight: 600;
    color: #8a8a8a;
  }
  span {
    color: #8a8a8a;
  }
  &.active {
    border: 1px solid #01599e;
    background-color: transparent;
  }
}
