.login-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-inner-wrap {
    max-width: 670px;
    width: 100%;
    background: #f8f8f8;
    padding: 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    img {
      max-width: 191px;
      display: inline-block;
      margin: 0 auto 40px;
      height: 39px;
    }
    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      margin: 0 0 16px;
    }
    span {
      display: block;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 40px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    li {
      list-style: none;
      display: flex;
      text-align: left;
      flex-direction: column;
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0;
      }
      button {
        background: #0176d3;
        border-radius: 10px;
        height: 40px;
        border: none;
        color: #fff;
        &:hover {
          background: #000;
        }
      }
      & > label {
        margin-bottom: 8px;
        display: block;
        line-height: 19px;
        color: #07070c;
      }
      input,
      select {
        height: 40px;
        padding-left: 12px;
        border: 1px solid #a3a3ab;
        border-radius: 5px;
        &::placeholder {
          color: #07070c;
        }
      }
      input {
        width: 100%;
        background: #f1f1f1;
        color: #07070c;
      }
      &.login-remember {
        flex-direction: row;
        justify-content: space-between;
        label {
          display: flex;
          align-items: center;
          margin: 0;
          input {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
