.custom_checkboxes {
  display: flex;
  align-items: center;

  .checkbox {
    position: relative;
    overflow: hidden;
    margin-right: 5px;
  }

  input {
    position: absolute;
    top: -100px;
    left: -100px;
  }

  span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #a3a3ab;
    background: #f1f1f1;
    color: #07070c;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  input:checked + span {
    border-color: #013b6a;
    background-color: #013b6a;
    color: white;
  }
}
