@import "./mixin";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap");

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

:root {
  --color-page-background: #{$dark_color};
  --color-text: #{$white_color};
  --theme-color: #{$theme_color};
  --white-color: #{$white_color};
  --black-color: #{$dark_color};
  --border: #{$dark_color};
  --font-family: "Lato", sans-serif;
}

body {
  margin: 0;
  background: #f1f1f1;
  color: var(--theme-color);
  -webkit-text-size-adjust: $w_100;
  -ms-text-size-adjust: none;
  font: 16px/25px var(--font-family);
  overflow-x: $hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
  font-family: "Nunito", sans-serif !important;

  @media (max-width: $breakpoint_laptop) {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
  font-family: var(--font-family);
  color: var(--theme-color);
}

.w-100 {
  width: $w_100;
}

.max-width {
  @include max_width;
}

.list-style-none {
  @include list_style_none;
}

.text-break {
  overflow-wrap: break-word;
}

.d-block {
  display: $d_block;
}

.d-none {
  display: $d_none;
}

.flex {
  display: $flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.content-justify-center {
  justify-content: center;
}

.content-justify-between {
  justify-content: space-between;
}

.content-justify-start {
  justify-content: flex-start;
}

.content-justify-end {
  justify-content: flex-end;
}

.column-direction {
  flex-direction: column;
}

.row-direction {
  flex-direction: row;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.m-0 {
  margin: 0;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

input[type="submit"],
button {
  cursor: pointer;
}

img {
  vertical-align: top;
  border-style: $d_none;
}

button:focus,
textarea:focus,
input:focus,
select {
  outline: $d_none;
}

textarea,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="date"],
select {
  color: #07070c;
  font-family: var(--font-family);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: $transparent;
  color: $transparent;
  cursor: pointer;
  height: auto;
  @include position_abs_left;
  right: 0;
  bottom: 0;
  width: auto;
}

*:focus {
  outline: $d_none;
}

.transition {
  @include transition;
}

#wrapper {
  width: $w_100;
  position: $position_rel;
  overflow: $hidden;
}

.container {
  width: $w_100;
  max-width: 1140px;
  padding: 0 15px;
  margin: $margin_auto;
}

.container.fluid {
  max-width: $d_none;
  padding: 0 40px;

  @media (max-width: $breakpoint_tablet) {
    padding: 0 15px;
  }
}

.container:after {
  display: block;
  clear: both;
  content: "";
}

.position_abs_right {
  @include position_abs_right;
}

.position_abs_left {
  @include position_abs_left;
}

.relative {
  position: relative;
}

.row {
  margin: 0 -15px;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;

  @media (max-width: 800px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 15px;
}

.col-2 {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 15px;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.form-group {
  &.filed-title {
    label {
      &:last-child {
        width: 133px;
      }
    }
  }

  label {
    font-size: 14px;
    line-height: 19px;
    margin: 0 0 8px;
    color: #07070c;
    display: flex;
    gap: 8px;
    font-family: "Nunito", sans-serif;

    small {
      font-size: 14px;
      color: #b83c3c;
    }
  }
}

.form-check-input {
  width: 60px !important;
  height: 30px;
  background-color: #d4d4d4;
  border: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

.form-control {
  @include flex;
  height: 40px;
  background-color: #f1f1f1 !important;
  border: 1px solid #a3a3ab;
  border-radius: 5px;
  color: #07070c;
  font-size: 14px;
  padding-left: 12px;
  z-index: 99;
  position: relative;

  &::placeholder {
    color: rgba(7 7 12 / 100%);
  }

  margin: 0 0 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../assets/images/select-down-angle.svg") no-repeat 96%
    center;
}

.width-50 {
  width: 50% !important;

  @media (max-width: $breakpoint_mobile_portrait) {
    width: 100% !important;
  }
}

table {
  background-color: transparent;
  max-width: 100%;
  border-collapse: collapse;
}

th {
  text-align: left;
}

table {
  width: 100%;
}

table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  //border: 1px solid #666666;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
}

table thead tr th {
  border-top: 1px solid #666666;
  text-align: center;
  text-transform: capitalize;
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 1px solid #666666;
}

table > thead > tr > th {
  border-bottom: 2px solid #666666;
  vertical-align: middle;
}

table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td {
  border-bottom: 0 none;
}

table > tbody + tbody {
  border-top: 2px solid #666666;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 19px 10px;

  @media (max-width: $breakpoint_laptop) {
    padding: 11px 10px;
  }
}

#root {
  overflow: hidden;
}

.simple-btn {
  display: flex;
  background-color: #1a1a2e;
  border-radius: 10px;
  min-width: 212px;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  color: #f8f8f8;
  font-size: 16px;
  border: 1px solid #1a1a2e;
  @include transition;

  &:hover {
    background-color: transparent;
    color: #1a1a2e;
  }
  &.simple-btn-hover {
    &:hover {
      color: #fff;
      background-color: #1a1a2e;
    }
  }

  &.reject-btn {
    background-color: #b83c3c;
    border: 1px solid #b83c3c;
    &:hover {
      color: #fff;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.panel-main-wrapper {
  ::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }
}

.picker-control {
  height: 40px !important;
  width: 70px !important;
  border: 1px solid #a3a3ab !important;
  border-radius: 5px !important;
  cursor: pointer;
  display: flex !important;
  align-items: center;
  padding-left: 12px !important;
  background: #f1f1f1 url("../../assets/images/select-down-angle.svg") no-repeat
    50px center !important;

  span {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    position: relative;
  }
}

.crossList {
  width: 45px;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    filter: opacity(0.4);
  }
}

.PhoneInput {
  .PhoneInputCountry {
    width: 90px;
    height: 40px;
    background-color: #f1f1f1 !important;
    border: 1px solid #a3a3ab;
    border-radius: 5px;
    justify-content: center;
    margin-right: 8px;

    .PhoneInputCountryIcon {
      border: none;
      box-shadow: none;
      background: transparent;

      img {
        height: 18px;
        width: 18px;
        border-radius: 100%;
        object-fit: cover;
      }
    }
  }

  & > input {
    height: 40px;
    background-color: #f1f1f1 !important;
    border: 1px solid #a3a3ab;
    border-radius: 5px;
    padding-left: 12px;
  }
}

.offcanvas.offcanvas-end {
  width: 600px !important;
}

.counter {
  &.form-control {
    flex-wrap: nowrap;
    padding: 0;
    align-items: center;
  }

  input {
    width: 100%;
    background: transparent;
    outline: 0;
    text-align: center;
    border: none;
  }

  button {
    background-color: transparent;
    border: none;
    padding: 6px 15px;
    font-size: 18px;
    font-weight: bold;

    &:first-child {
      border-right: 1px solid gray;
    }

    &:last-child {
      border-left: 1px solid gray;
    }
  }
}

.offcanvas-title {
  margin: 0;
  line-height: 1.2;
  font-weight: 700;
  font-size: 32px;
}

.errorMessage {
  color: #b83c3c;
  margin-bottom: 0;
  font-size: 14px;
  margin-top: 5px;
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: -5px;
  font-size: 14px;
  color: #dc3545;
}

.add-position {
  background: #f1f1f1;
  border: 1px dashed #a3a3ab;
  border-radius: 5px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a3a3ab;
  height: 40px;
  cursor: pointer;
  width: 100%;

  p {
    margin: 0;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-time-picker__wrapper {
  display: flex;
  .react-time-picker__inputGroup {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 2px;
    span {
      flex: 1 !important;
      flex-shrink: 0 !important;
    }
    .react-time-picker__inputGroup__hour,
    .react-time-picker__inputGroup__minute {
      width: 18px !important;
    }
  }
  input[type="number"] {
    padding-right: 0 !important;
    margin-right: 0 !important;
    // width: 10px !important;
    border: 0;
    background: transparent;
  }

  .react-time-picker__inputGroup__input {
    background: transparent;
    border: 0;
  }
}

.icon_down_dir {
  display: none;
}
.bin {
  position: absolute;
  right: 44px;
  width: 18px;
  top: 26px;
  filter: contrast(0.5);
}

.rs-picker-toggle {
  border-radius: 5px !important;
  background-color: #f8f8f8 !important;
  border-color: #e3e3e3 !important;
  padding: 10px 36px 10px 10px !important;
  box-shadow: none !important;
}
.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-btn,
.rs-picker-input .rs-picker-toggle,
.rs-picker-toggle-textbox {
  background-color: #f8f8f8 !important;
}
.rs-picker-toggle-textbox::placeholder {
  color: #07070c !important;
}
