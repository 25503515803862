@import "../assets/scss/mixin";

.panel-wrapper {
  .panel-main-content {
    width: calc(100% - 239px);
    margin-left: auto;
    padding: 0 24px 32px;
    position: relative;

    @media (max-width: $breakpoint_tablet) {
      width: calc(100%);
    }

    .panel-header {
      width: 100%;
      height: 76px;

      .left-side {
        h1 {
          margin: 0;
          font-weight: 700;
          font-size: 28px;
          line-height: 38px;
        }
      }

      .right-side {
        display: flex;
        align-items: center;

        figure {
          margin: 0;

          img {
            flex: 0 0 36px;
            height: 36px;
            border-radius: 100%;
            margin-right: 8px;
            display: flex;
            object-fit: cover;
          }

          figcaption {
            line-height: 1;

            strong {
              font-family: "Nunito", sans-serif;
              position: relative;
              padding-right: 19px;
              display: block;
              line-height: 19px;

              &:before {
                content: "";
                position: absolute;
                right: 0;
                top: 8px;
                width: 8px;
                height: 5px;
                background: url("../assets/images/down-angle-arrow.svg")
                  no-repeat center;
                background-size: cover;
              }
            }

            small {
              font-size: 12px;
              line-height: 16px;
              color: #767682;
              font-family: "Nunito", sans-serif;
            }
          }
        }
      }
    }

    .side-menu-btn {
      background: transparent;
      position: absolute;
      left: 10px;
      top: 10px;
      font-size: 20px;
      border: 1px solid #000;
      border-radius: 5px;
      padding: 5px 10px;
      @include transition;
      display: none;

      &.show {
        visibility: hidden;
        opacity: 0;
      }

      @media (max-width: $breakpoint_tablet) {
        display: block;
      }
    }

    .panel-main-wrapper {
      min-height: calc(100vh - 108px);
      padding: 16px 0 0;

      .panel-card {
        margin: 0 auto 70px;
        width: 100%;

        .panel-body {
          background: #f8f8f8;
          box-shadow: 20px 20px 11px rgba(0, 0, 0, 0.01),
            11px 11px 9px rgba(0, 0, 0, 0.05), 5px 5px 7px rgba(0, 0, 0, 0.09),
            1px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          padding: 24px 16px;
        }
      }
    }
  }
}

.table-btn {
  display: inline-block;
  margin: 2px 0 2px 5px;
  background: var(--theme-color);
  font-size: 12px;
  border-radius: 0.3rem;
  border: none;
  letter-spacing: 1px;
  white-space: nowrap;
  color: var(--white-color);
  line-height: 30px;
  padding: 0 15px;
  @include transition;

  i {
    margin-right: 5px;
  }

  &:hover {
    color: #ffffff !important;
    background: var(--theme-color);
  }
}

.header-search {
  position: relative;
  margin-right: 40px;
  width: 214px;

  input {
    width: 100%;
    height: 43px;
    padding-left: 12px;
    padding-right: 38px;
    background: transparent;
    border: 1px solid #a3a3ab;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #8a8a8a;

    &::placeholder {
      color: #8a8a8a;
    }
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
    width: 38px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
