@import "../../assets/scss/mixin";

.search-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .search-right-side {
    gap: 16px;

    .header-search {
      margin-right: 0;
      width: 300px;
      height: 100%;

      input {
        border: 1px solid #e3e3e3;
        border-radius: 5px;
        color: #07070c;

        &::placeholder {
          color: #07070c;
        }
      }

      button {
        img {
          filter: brightness(0.5);
        }
      }
    }
  }
}

.theme-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 16px;
  background: var(--theme-color);
  border: none;
  color: #f8f8f8;
  font-weight: 700;
  font-size: 16px;
  border-radius: 10px;

  img {
    margin-right: 8px;
  }
}

.search-filter {
  display: flex;
  align-items: center;

  span {
    color: #121212;
    font-size: 14px;
    padding-left: 16px;
    height: 43px;
    display: flex;
    align-items: center;
    border: 1px solid #e3e3e3;
    border-radius: 5px 0 0 5px;
    border-right: none;
  }

  select {
    padding-left: 5px;
    font-size: 14px;
    height: 43px;
    background: transparent;
    border: 1px solid #e3e3e3;
    border-left: none;
    width: 139px;
    color: #121212;
    border-radius: 0 5px 5px 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("../../assets/images/select-angle-arrow.svg") no-repeat;
    background-position: 111px center;
  }
}

.export-btn {
  display: flex;
  align-items: center;
  min-width: 113px;
  height: 40px;
  padding: 0 10px;
  position: relative;
  overflow: hidden;
  gap: 12px;
  border: 1px solid #1a1a2e;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  color: #1a1a2e;
  justify-content: center;

  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}
