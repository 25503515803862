.request_pending {
    .card {
        padding: 10px;
        background: #F8F8F8;
        border: 1px solid #E3E3E3;
        border-radius: 10px;
        color: #07070C;

        .requester_info {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            img {
                margin-right: 10px;
                width: 55px;
                height: 55px;
                border-radius: 100px;
                border: 1px solid white;
            }

            figure {
                margin-bottom: 0;

                h5 {
                    font-size: 16px;
                    color: #07070C;
                }

                p {
                    color: #07070C;
                }
            }
        }

        .unpaid_leave {
            background: #F1F1F1;
            border-radius: 10px;
            padding: 15px;
            margin: 10px 0 20px;

            h5 {
                border-bottom: 2px solid #D4D4D4;
                margin-bottom: 13px;
                padding-bottom: 13px;
                font-size: 16px;
            }

            ul {
                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    p {
                        font-size: 14px;
                        color: #07070C;
                        margin: 0;
                    }
                }
            }
        }
    }
}