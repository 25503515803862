@import "../../assets/scss/mixin";

.search-wrappers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .search-right-side {
    gap: 16px;
    flex-wrap: wrap;

    .header-search {
      margin-right: 0;
      width: 215px;

      input {
        border: 1px solid #e3e3e3;
        border-radius: 5px;
        color: #07070c;
        padding: 5px;

        &::placeholder {
          color: #07070c;
        }
      }

      button {
        img {
          filter: brightness(0.5);
        }
      }
    }

    .search-filter {
      select {
        border-left: 1px solid #e3e3e3;
        border-radius: 5px;
        width: 100px;
        background-position: 82px center;
        padding: 5px;
      }
    }

    .calender {
      height: 40px;
      padding: 5px;
      background: transparent;
      border: 1px solid #e3e3e3;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px;
      color: #000;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;

      img {
        cursor: pointer;

        &:first-child {
          transform: rotate(90deg);
        }

        &:last-child {
          transform: rotate(270deg);
        }
      }

      p {
        margin: 0 15px;
      }
    }
  }
}

.multiSelectContainer {
  width: 144px !important;

  .searchWrapper {
    -webkit-line-clamp: 1;
    display: flex;
    -webkit-box-orient: vertical;
    overflow-x: scroll;
    margin-bottom: 0;
    border: 1px solid #e8e8e8;

    #search_input {
      width: 110px !important;
      cursor: pointer;

      &::placeholder {
        color: #07070c;
      }
    }

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    .chip {
      background: #0176d3;
      color: white;
    }

    &::after {
      content: "";
      background: url("../../assets/images/select-angle-arrow.svg") no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 6px;
      background-position: 10px;
    }
  }

  .optionListContainer {
    z-index: 6 !important;
    ul {
      li {
        &.highlightOption {
          background: #0176d3;
        }

        &:hover {
          background: #0176d3;
        }

        .checkbox {
          border-radius: 0;
        }
      }
    }
  }
}

.export-btn {
  display: flex;
  align-items: center;
  min-width: 113px;
  height: 40px;
  padding: 0 10px;
  position: relative;
  overflow: hidden;
  gap: 12px;
  border: 1px solid #1a1a2e;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  color: #1a1a2e;
  justify-content: center;
  background: #f8f8f8;

  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  &.issue {
    color: #b83c3c;
    border: 1px solid #b83c3c;
  }
}

.add-form {
  .detail {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .optional {
    color: #a3a3ab;
  }

  .date {
    z-index: 0;
    position: relative;
  }

  .editor-toolbar {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #a3a3ab;

    .rdw-option-wrapper {
      &[title="Superscript"] {
        display: none;
      }

      &[title="Subscript"] {
        display: none;
      }

      &[title="Monospace"] {
        display: none;
      }
      &[title="Indent"] {
        display: none;
      }
      &[title="Outdent"] {
        display: none;
      }
    }
  }

  .editor-color {
    padding: 10px;
    display: block;
    background-color: #f1f1f1;
    border: 1px solid #a3a3ab;
    border-radius: 5px;
    height: 100px;
    overflow-y: scroll;

    .public-DraftStyleDefault-block {
      margin: 0 !important;
    }

    .rdw-editor-toolbar {
      padding: 0;
      border-radius: 0;
      border-bottom: 1px solid #dfdfdf;
      background: #f1f1f1;

      .rdw-option-wrapper {
        background: #f1f1f1;
      }
    }
  }
}

.simple-btn {
  min-width: 100px;
}

.add-position {
  background: #f1f1f1;
  border: 1px dashed #a3a3ab;
  border-radius: 5px;
  text-align: center;
  padding: 6px 10px;
  height: 40px;
  cursor: pointer;
  color: #a3a3ab;
}

.issues {
  .nav-tabs {
    margin-bottom: 20px;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: black;
    background-color: transparent;
    border-color: transparent;
    border-bottom: 2px solid #0176d3;
    font-family: "Lato", sans-serif !important;
    font-weight: 700;
  }

  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: transparent;
    color: #0176d3;
    border-bottom: 2px solid #0176d3;
  }

  .nav-tabs .nav-link {
    color: black;
    font-family: "Nunito", sans-serif !important;
    padding: 0 0 3px 0;
    margin: 0 15px 0 0;
    cursor: pointer;
  }

  .title {
    color: #121212;
    font-size: 16px;
    margin: 20px 0 15px;
    font-weight: bold;
    font-family: "Nunito", sans-serif;
  }

  .vacant_shift {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    background: #5b78b1;
    border-radius: 10px;
    padding: 10px;
    font-family: "Nunito", sans-serif;

    .time_site {
      h5 {
        font-size: 14px;
        color: #f8f8f8;
        margin-bottom: 5px;
        font-weight: 700;
      }

      p {
        color: #f8f8f8;
        font-size: 14px;
        margin: 0;
      }
    }

    .job {
      h5 {
        background: #f8f8f8;
        border-radius: 10px;
        color: #5b78b1;
        padding: 3px 10px;
        font-weight: 600;
        font-size: 14px;
        margin: 0;
        display: inline-block;
      }
    }
  }

  .overlaping_shift {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    background: #ffe9e9;
    border-radius: 10px;
    padding: 10px;
    font-family: "Nunito", sans-serif;

    .time_site {
      h5 {
        font-size: 14px;
        color: #b83c3c;
        margin-bottom: 5px;
        font-weight: 700;
      }

      img {
        border: 2px solid #bd4343;
        border-radius: 100px;
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }

      p {
        color: #bd4343;
        margin-bottom: 0;
        font-weight: 600;
      }
    }
  }

  .shift_replacement {
    background: #619558;

    .job {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: end;

      h5 {
        color: #619558;
        display: inline-block;
      }

      h4 {
        background: #f8f8f8;
        border-radius: 10px;
        color: #619558;
        padding: 8px;
        font-weight: 600;
        font-size: 14px;
        margin: 0;
        line-height: 20px;
        cursor: pointer;
      }
    }

    .time_site {
      p {
        line-height: 15px;
      }
    }

    .dummy {
      border: 2px solid #c5ecbf;
      border-radius: 100px;
      width: 35px;
      height: 35px;
      margin-right: 10px;
    }

    .arrow {
      margin: 0 20px;
    }
  }
}
