@import "../../assets/scss/mixin";

.head-title {
  margin-bottom: 24px;

  small {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: block;
    color: #767682;
    margin: 0 0 24px;
  }

  h2 {
    margin: 0;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
  }
}

.week-selector {
  .rs-picker-toggle-textbox,
  .rs-btn-default {
    height: 43px !important;
  }
}
.scheduling_table {
  .table-responsive {
    &.alternative-colors {
      table {
        tr {
          th {
            background: #1a1a2e;
            color: #f8f8f8;
            font-weight: 400;
          }
        }

        tbody {
          tr {
            &:nth-child(odd) {
              background: #f1f1f1;
            }
          }
        }
      }

      div {
        &[role="table"] {
          [role="rowgroup"] {
            &:first-child {
              [role="row"] {
                & > div {
                  background: #1a1a2e;
                }

                .table-content {
                  color: #f8f8f8;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }

    & > div {
      &:last-child {
        nav {
          background-color: transparent;
          justify-content: flex-start;

          & > div {
            &:last-child {
              margin-left: auto;
            }
          }
        }
      }
    }

    div {
      &[role="table"] {
        background-color: transparent;

        [role="rowgroup"] {
          &:first-child {
            [role="row"] {
              & > div {
                &:first-child {
                  border-radius: 10px 0 0 0;

                  .rdt_TableCol_Sortable {
                    justify-content: start;
                  }
                }

                &:nth-child(2) {
                  background-color: #e0f1ff;
                }

                &:last-child {
                  border-radius: 0 10px 0 0;
                }
              }
            }

            .table-content {
              font-weight: 400;
            }
          }

          [role="row"] {
            background-color: transparent;
            border: none;
            height: auto;

            &:last-child {
              border-bottom: 1px solid #e3e3e3;
            }

            figure {
              margin: 0;
              display: flex;
              align-items: center;

              img {
                display: flex;
                width: 35px;
                flex: 0 0 35px;
                margin-right: 10px;
                height: 35px;
                border-radius: 100%;
                object-fit: cover;
              }
            }

            & > div {
              border: 1px solid #e3e3e3;
              text-align: left;
              padding: 0 16px;
              border-right: none;
              border-bottom: none;
              width: 235px;

              &:last-child {
                border-right: 1px solid #e3e3e3;
              }

              div {
                width: 100%;
                overflow: initial;
              }
            }

            .rdt_TableCol_Sortable {
              justify-content: center;
            }

            .table-content {
              color: #07070c;
              font-family: "Nunito", sans-serif;
              font-size: 14px;
              width: auto;

              span {
                background: #0176d3;
                border-radius: 10px;
                color: white;
                padding: 8px 15px;
              }
            }

            .jobPosition {
              padding: 10px 0;

              h3 {
                margin: 0;
                font-size: 14px;
              }

              p {
                font-size: 14px;
                margin: 0;
                display: flex;
                color: #a3a3ab;

                img {
                  margin-right: 5px;
                  width: 17px;
                }
              }
            }

            .vacant_shift {
              border-radius: 10px;
              margin: 10px 0;
              width: 100%;
              height: 115px;
              display: flex;
              align-items: center;
              justify-content: center;

              p {
                margin: 0;
                font-size: 18px;
              }

              &.red {
                background: #ffd1c2;
                border: 1px dashed #e48060;

                p {
                  color: #e48060;
                }
              }

              &.blue {
                background: #dfeaff;
                border: 1px dashed #5b78b1;

                p {
                  color: #5b78b1;
                }
              }
            }
          }
        }
      }

      [role="rowgroup"] {
        [role="cell"] {
          &:nth-child(2) {
            background-color: #e0f1ff;
          }
        }
      }

      .search-filter {
        select {
          width: 200px;
          border-left: 1px solid #e3e3e3;
          border-radius: 5px;
          background-position: 185px center;
        }
      }
    }
  }

  .add-user-wrapper {
    display: flex;

    .add-form {
      width: calc(100% - 212px);
      padding-right: 48px;
    }

    .file-uploader {
      max-width: 212px;
    }
  }

  .add-form {
    ul {
      margin: 0 -8px;

      &.position-color {
        //flex-wrap: nowrap;
        li {
          &:first-child {
            width: calc(100% - 148px);
          }

          width: auto;
        }
      }

      li {
        width: 100%;
        padding: 0 8px;
        margin-bottom: 16px;

        h6 {
          color: #121212;
          margin: 10px 0 10px;
        }
      }
    }
  }

  .file-uploader {
    width: 100%;

    figure {
      margin: 0 0 18px;
      border-radius: 10px;
      overflow: hidden;
      background-color: #e3e3e3;

      img {
        width: 100%;
        max-width: 212px;
        height: 212px;
        object-fit: cover;
      }
    }

    button {
      position: relative;

      input {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        cursor: pointer;
        opacity: 0;
      }
    }
  }
}
.assign-card {
  border-radius: 10px;
  padding: 10px;
  overflow: initial !important;
  height: unset !important;
  time {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    span {
      margin-left: auto;
      // background-color: #fff;
      width: 40px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      // color: #e48060;
      border-radius: 30px;
    }
  }
  h6 {
    margin: 0 0 5px;
    color: white;
  }
  img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border: 2px solid #ffd1c2;
    border-radius: 100%;
  }
}
.warning_card {
  display: flex;
  justify-content: space-between;
  background: #ffe9e9 !important;
  border: 2px dashed #b83c3c !important;
  border-radius: 10px;
  margin: 10px 0;
  padding: 8px;
  height: 115px;
  color: #b83c3c;
  .role {
    margin-right: 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    img {
      margin-right: 3px;
      width: 17px;
    }
    h5 {
      font-size: 13px;
      color: #c7162b;
    }
    h4 {
      font-size: 16px;
      color: #c7162b;
    }
    p {
      font-size: 16px;
      color: #c7162b;
    }
  }
  span {
    width: 50px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #c7162b;
    border-radius: 30px;
    color: white;
    font-weight: bold;
  }
}
.dropdown {
  width: auto !important;
  .dropdown-toggle {
    background-color: transparent;
    border: none;
    padding: 0;
    &::after {
      display: none;
    }
    &:active {
      background-color: transparent;
    }
  }
  .dropdown-menu {
    width: auto !important;
    a {
      font-size: 12px;
    }
  }
}
