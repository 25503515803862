@import "../../assets/scss/mixin";

.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;

  &.new-site-modal {
    .modal-dialog {
      max-width: 695px;
    }
  }

  &.export-modal {
    .modal-dialog {
      max-width: 1200px;
    }
  }

  .modal-dialog {
    min-height: calc(100% - (1.75rem * 2));
    max-width: 820px;
    margin: 1.75rem auto;
    display: flex;
    padding: 0 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    .bin {
      width: 21px;
      margin-right: 10px;
      filter: brightness(0.5);
      position: relative;
      top: auto;
      right: auto;
    }
    .modal-content {
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #f8f8f8;
      background-clip: padding-box;
      border-radius: 0.3rem;
      outline: 0;

      .modal-title {
        padding: 32px 24px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          margin: 0;
          line-height: 1.2;
          font-weight: 700;
          font-size: 28px;
          color: black;
        }

        img {
          cursor: pointer;
        }
      }

      .modal-body {
        flex: 1 1 auto;
        padding: 40px 24px 24px;

        @media (max-width: $breakpoint_tablet) {
          padding: 20px 15px;
        }

        .publish_shift {
          background: #f1f1f1;
          border-radius: 10px;
          padding: 15px;

          .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h5 {
              font-weight: 700;
              font-size: 16px;
              margin: 5px 0;
            }

            p {
              margin: 5px 0;
            }
          }

          .line {
            background: #d4d4d4;
            border-radius: 10px;
            width: 100%;
            height: 2px;
            display: block;
            margin: 15px 0;
          }

          .simple-btn {
            width: auto;
          }
        }

        .export_modal {
          .search-filter {
            select {
              width: 100%;
              border-left: 1px solid #e3e3e3;
              border-radius: 5px;
              background-position-x: 98%;
              background-position-y: center;
            }
          }

          .multiSelectContainer {
            width: 100% !important;

            .searchWrapper {
              #search_input {
                width: 100% !important;
              }
            }
          }

          .form-control {
            background: #f8f8f8 !important;
            border: 1px solid #e3e3e3;
            padding: 5px;
          }

          .table-responsive {
            .rdt_TableBody {
              height: 300px;
              overflow: auto;
            }
          }
        }

        .approvalModal {
          .vacant_shift {
            display: flex;
            justify-content: space-between;
            gap: 40px;
            background: #619558;
            border-radius: 10px 10px 0 0;
            padding: 10px;
            font-family: "Nunito", sans-serif;

            .time_site {
              h5 {
                font-size: 14px;
                color: #f8f8f8;
                margin-bottom: 5px;
                font-weight: 700;
              }

              p {
                color: #f8f8f8;
                font-size: 14px;
                margin: 0;
              }
            }

            .job {
              h5 {
                background: #f8f8f8;
                border-radius: 10px;
                color: #619558;
                padding: 3px 10px;
                font-weight: 600;
                font-size: 14px;
                margin: 0;
                display: inline-block;
              }
            }
          }

          .replacement {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 25px 10px 15px;
            background: #f8f8f8;
            border: 2px solid #619558;
            border-radius: 0px 0px 10px 10px;
            margin-bottom: 10px;
            margin-top: 0;

            &::after {
              content: "";
              position: absolute;
              width: 85%;
              border: 1px dashed #b8b8b8;
            }

            .user {
              position: relative;
              z-index: 99;

              .dummy {
                border: 2px solid #c5ecbf;
                border-radius: 100px;
                width: 45px;
                height: 45px;
              }

              span {
                img {
                  position: absolute;
                  left: 35px;
                }
              }
            }
          }
        }

        .reject_timeoff_modal {
          label {
            font-weight: bold;
          }

          textarea {
            min-height: 120px;
          }
        }

        .add_timeoff_modal {
          p,
          h6 {
            color: #07070c;
          }

          textarea {
            min-height: 100px;
            margin: 25px 0 15px;
          }

          .card {
            background: #f8f8f8;
            border: 1px dashed #01599e;
            border-radius: 10px;
            margin: 5px 0 25px;
          }

          .upload-btn-wrapper {
            position: relative;
            overflow: hidden;
            cursor: pointer;
            color: #01599e;
            width: 100%;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              margin-right: 10px;
            }
          }

          .upload-btn-wrapper input[type="file"] {
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }
        }
      }
    }
  }
}
