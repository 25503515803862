.jobPosition {
  span {
    padding: 10px;
    display: block;
    text-align: center;
  }
}

.rdt_TableRow {
  & > div {
    & > div {
      width: 100%;
    }
  }
}

.table-responsive {
  &.alternative-colors {
    table {
      tr {
        th {
          background: #1a1a2e;
          color: #f8f8f8;
          font-weight: 400;
        }
      }

      tbody {
        tr {
          &:nth-child(odd) {
            background: #f1f1f1;
          }
        }
      }
    }

    div {
      &[role="table"] {
        [role="rowgroup"] {
          &:first-child {
            [role="row"] {
              & > div {
                background: #1a1a2e;
              }

              .table-content {
                color: #f8f8f8;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  & > div {
    &:last-child {
      nav {
        background-color: transparent;
        justify-content: flex-start;

        & > div {
          &:last-child {
            margin-left: auto;
          }
        }
      }
    }
  }

  div {
    &[role="table"] {
      background-color: transparent;

      [role="rowgroup"] {
        &:first-child {
          [role="row"] {
            & > div {
              &:first-child {
                border-radius: 10px 0 0 0;
              }

              &:last-child {
                border-radius: 0 10px 0 0;
              }
            }
          }

          .table-content {
            font-weight: 700;
          }
        }

        [role="row"] {
          background-color: transparent;
          border: none;
          height: 51px;

          figure {
            margin: 0;
            display: flex;
            align-items: center;

            img {
              display: flex;
              width: 35px;
              flex: 0 0 35px;
              margin-right: 10px;
              height: 35px;
              border-radius: 100%;
              object-fit: cover;
            }
          }

          & > div {
            border: 1px solid #e3e3e3;
            text-align: left;
            padding: 8px 24px 8px 24px;
            border-right: none;
            border-bottom: none;

            &:last-child {
              border-right: 1px solid #e3e3e3;
            }
          }

          .table-content {
            color: #07070c;
            font-family: "Nunito", sans-serif;
            font-size: 14px;
          }
        }
      }
    }
  }
}
